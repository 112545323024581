import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0bb78cae = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _17ea2fae = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _445ec7e6 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _85032ca8 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _f4a19334 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _12427f2c = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _b288ab3e = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _345bec7e = () => interopDefault(import('../modules/part-finder/pages/FindMyTractor.vue' /* webpackChunkName: "" */))
const _5fc376c6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _309311a1 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _b670e826 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _042f028d = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _da527a9c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _1a8d21dc = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _073842e4 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _620be15c = () => interopDefault(import('../modules/part-finder/pages/Finders.vue' /* webpackChunkName: "" */))
const _0d30d72e = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _4ea1abce = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _68ecfb84 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _34b9f6c8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _57818eba = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _7daba2bd = () => interopDefault(import('../pages/customer/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/customer/MyAccount/useSidebarLinkGroups" */))
const _6dc23e6e = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _a5d68166 = () => interopDefault(import('../modules/part-finder/pages/Brands.vue' /* webpackChunkName: "" */))
const _e0ded86a = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _4b2e4004 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _4ebcf62a = () => interopDefault(import('../modules/part-finder/pages/Models.vue' /* webpackChunkName: "" */))
const _0369ef6f = () => interopDefault(import('../modules/part-finder/pages/Parts.vue' /* webpackChunkName: "" */))
const _89d6da14 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _0b49a8a6 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt",
    component: _0bb78cae,
    name: "blog___ad_nb_no"
  }, {
    path: "/checkout",
    component: _17ea2fae,
    name: "checkout___ad_nb_no",
    children: [{
      path: "bestilling",
      component: _445ec7e6,
      name: "checkout-order___ad_nb_no"
    }, {
      path: "konto",
      component: _85032ca8,
      name: "checkout-account___ad_nb_no"
    }, {
      path: "takk",
      component: _f4a19334,
      name: "checkout-thank-you___ad_nb_no"
    }]
  }, {
    path: "/customer",
    component: _12427f2c,
    meta: {"titleLabel":"Account"},
    name: "customer___ad_nb_no",
    children: [{
      path: "addresses-details",
      component: _b288ab3e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ad_nb_no"
    }, {
      path: "find-my-tractor",
      component: _345bec7e,
      meta: {"titleLabel":"Find my tractor"},
      name: "customer-find-my-tractor___ad_nb_no"
    }, {
      path: "my-profile",
      component: _5fc376c6,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___ad_nb_no"
    }, {
      path: "order-history",
      component: _309311a1,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ad_nb_no"
    }, {
      path: "addresses-details/create",
      component: _b670e826,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ad_nb_no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _042f028d,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ad_nb_no"
    }, {
      path: "order-history/:orderId",
      component: _da527a9c,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ad_nb_no"
    }]
  }, {
    path: "/faq.html",
    component: _1a8d21dc,
    name: "faq___ad_nb_no"
  }, {
    path: "/onskeliste",
    component: _073842e4,
    name: "wishlist___ad_nb_no"
  }, {
    path: "/parts",
    component: _620be15c,
    name: "part-finder___ad_nb_no"
  }, {
    path: "/search",
    component: _0d30d72e,
    name: "search___ad_nb_no"
  }, {
    path: "/sveacheckout",
    component: _4ea1abce,
    name: "sveaCheckout___ad_nb_no"
  }, {
    path: "/catalog/Category",
    component: _68ecfb84,
    name: "catalog-Category___ad_nb_no"
  }, {
    path: "/customer/reset-password",
    component: _34b9f6c8,
    alias: "/customer/account/createPassword",
    name: "reset-password___ad_nb_no"
  }, {
    path: "/sveacheckout/confirmation",
    component: _57818eba,
    name: "sveaCheckoutConfirmation___ad_nb_no"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _12427f2c,
    name: "customer-MyAccount-MyAccount___ad_nb_no"
  }, {
    path: "/customer/MyAccount/useSidebarLinkGroups",
    component: _7daba2bd,
    name: "customer-MyAccount-useSidebarLinkGroups___ad_nb_no"
  }, {
    path: "/aktuelt/:slug+",
    component: _6dc23e6e,
    name: "blogPost___ad_nb_no"
  }, {
    path: "/parts/:finder",
    component: _a5d68166,
    name: "part-finder-finders___ad_nb_no"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _e0ded86a,
    name: "attributeCollection___ad_nb_no"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _4b2e4004,
    name: "attributeCollectionItem___ad_nb_no"
  }, {
    path: "/parts/:finder/:brand",
    component: _4ebcf62a,
    name: "part-finder-models___ad_nb_no"
  }, {
    path: "/parts/:finder/:brand/:model",
    component: _0369ef6f,
    name: "part-finder-parts___ad_nb_no"
  }, {
    path: "/",
    component: _89d6da14,
    name: "home___ad_nb_no"
  }, {
    path: "/:slug+",
    component: _0b49a8a6,
    name: "page___ad_nb_no"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
